import React from 'react';
import './App.css';
import MitsizateText from "./MitsizateText";

function App() {
    return (
        <div className="App">
            <title>iMiz</title>
            <MitsizateText/>
        </div>
    );
}

export default App;
